
import { getBranch } from '../graphql/branch';
import { Auth, Amplify, graphqlOperation, API } from 'aws-amplify';
import { SearchOperationBuilder, SearchParam } from 'wherecomposer/dist/SearchParam';
import { ConditionCreator } from 'wherecomposer/dist/ConditionCreator';

let branchAppsyncUrl = process.env.GATSBY_BRANCH_APPSYNC_URL;
let branchApiKey = process.env.GATSBY_BRANCH_API_KEY;

const getBranchLocation = async (languageGuid,languageCode) => {
    let searchParamArray = [];
    let objSearchParam = new SearchParam();
    objSearchParam.searchParamCreate("LanguageGuid", "Guid", languageGuid, SearchOperationBuilder.create(true, false, false));
    searchParamArray.push(objSearchParam);

    let objSearchParam2 = new SearchParam();
    objSearchParam2.searchParamCreate("IsBranch", "Boolean", true, SearchOperationBuilder.create(true, false, false));
    searchParamArray.push(objSearchParam2);

    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(searchParamArray);
    let searchParam = objWhere.toJsonString();

    const locationConfig = {
        API: {
            'graphql_endpoint': branchAppsyncUrl,
            graphql_headers: async () => {
                const currentSession = await Auth.currentSession();
                return {
                    'Authorization': currentSession.getIdToken().getJwtToken(),
                    'language': languageCode,
                    'x-api-key': branchApiKey
                };
            }
        }
    }

    Amplify.configure(locationConfig);

    return await API.graphql(graphqlOperation(getBranch, { OperationList: searchParam }));
}

export {
    getBranchLocation
}