import React, { useEffect, useState } from "react";
import { Fragment } from 'react';
import "../../styles/alert-message.scss";

export default function ErrorAlert(props) {
    const [showAlertMessage, setShowAlertMessage] = useState(false);

    useEffect(() => {
        if (props.message !== null && props.message !== undefined) {
            setShowAlertMessage(true);
        } else {
            setShowAlertMessage(false);
        }

        let timer = setTimeout(() => setShowAlertMessage(false), 5000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <Fragment>
            {
                showAlertMessage ? (
                    <div className="alert alert-danger success_alert_message" role="alert" style={{ marginTop: "40px" }}>
                        {props.message}
                    </div>
                ) : null
            }
        </Fragment>
    )
};