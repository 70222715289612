export let getBranch = `query getBranch($OperationList:[OperationList]) {
    getBranch(OperationList: $OperationList) 
    {
        OfficeName
        OfficeShortName
        CountryGuid
        CurrencyGuid
        LocationGUID
        GroupGuid
        LanguageGuid
        IsBranch
    }
}`;